import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
  item: {
    // display: "block",
    // paddingTop: 0,
    // paddingBottom: 0,
    display: "flex",
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
    padding: "30px"
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    padding: "30px"
  },
  // button: {
  //   color: theme.palette.primary.main,
  //   padding: "13px 20px",
  //   justifyContent: "flex-start",
  //   textTransform: "none",
  //   marginBottom: "8px",
  //   letterSpacing: 0,
  //   width: "100%",
  //   fontWeight: "500",

  //   "&:hover": {
  //     color: "#ffff",
  //     background: "linear-gradient(359.12deg, #FF6600 9.14%, #3333FF 110.76%)",
  //     borderRadius: "0px",
  //     "& $icon": {
  //       color: "#ffff",
  //     },
  //   },
  // },
  button: {
    // color: "rgba(255, 255, 255, 0.87)",
    color: "rgba(0, 0, 0, 0.60)",
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    margin: "0px 10px 5px",
    fontFamily: "Poppins",
    lineHeight: "26.531px",
    // marginBottom: "5px",
    borderRadius: "10px",
    opacity: 1,
    fontWeight: "500",
    fontSize: "16px",
    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "&:hover": {
      color: "#ffff",
      background: "linear-gradient(262deg, #62D3F0 13.12%, #35A5F5 83.57%)",
      borderRadius: "10px",
      "& $icon": {
        color: "#ffff",
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: 500,
        fontSize: "16px",
        whiteSpace: "pre",
      },
    },
  },
  buttonLeaf: {
    // color: "rgba(255, 255, 255, 0.87)",
    color: "rgba(0, 0, 0, 0.60)",
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    margin: "0px 10px 5px",
    fontFamily: "Poppins",
    lineHeight: "26.531px",
    // marginBottom: "5px",
    borderRadius: "10px",
    opacity: 1,
    fontWeight: "500",
    fontSize: "16px",
    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "&:hover": {
      color: "#ffff",
      background: "linear-gradient(262deg, #62D3F0 13.12%, #35A5F5 83.57%)",
      borderRadius: "10px",
      "& $icon": {
        color: "#ffff",
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: 500,
        fontSize: "16px",
        whiteSpace: "pre",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
    color: "rgba(0, 0, 0, 0.60)",
    width: "32px",
    height: "32px",
  },
  title: {
    marginRight: "auto",
    fontSize: "16px"
  },
  active: {
    color: "#ffff",
    background: "linear-gradient(262deg, #62D3F0 13.12%, #35A5F5 83.57%)",
    borderRadius: "10px",
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#ffff",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle}>
          {Icon && <Icon className={classes.icon} />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse style={{ width: '100%' }} in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      {" "}
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {Info && <Info />}
        </Button>
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
