import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout2 from "./layouts/LoginLayout2";

export const routes = [
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    exact: true,
    path: "/sign-up",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/signup/SignUp")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout2,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout2,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/Dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/developers",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Developers/Developers")),
  },
  {
    exact: true,
    path: "/developers-overview",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Developers/DevelopersOverview")),
  },
  {
    exact: true,
    path: "/account-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Home/AccountDetails")
    ),
  },
  {
    exact: true,
    path: "/billing-plans",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Billing/BillingPlans")
    ),
  },
  {
    exact: true,
    path: "/balance",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Balance/Balance")),
  },
  {
    exact: true,
    path: "/balance-manage-dialog",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Balance/BalanceManage")),
  },
  {
    exact: true,
    path: "/balance-setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Balance/BalanceSetting")),
  },

  {
    exact: true,
    path: "/billing",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Billing/Billing")),
  },
  {
    exact: true,
    path: "/payment",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Payments/Payment.js")
    ),
  },

  {
    exact: true,
    path: "/payment-link",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PaymentLink/Index")
    ),
  },


  {
    exact: true,
    path: "/payment-screen",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Payments/PaymentScreen/PaymentScreen")
    ),
  },



  {
    exact: true,
    path: "/add-owner",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Business/AddOwner")
    ),
  },
  {
    exact: true,
    path: "/fiat-wallet",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Wallet/FiatWallet/index")
    ),
  },

  {
    exact: true,
    path: "/notifications",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Notification/Notification")
    ),
  },

  {
    exact: true,
    path: "/invoice",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Invoice/Index")),
  },
  {
    exact: true,
    path: "/crypto-dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/CryptoWallet/CryptoWallet")),
  },
  {
    exact: true,
    path: "/stepper",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Business/BusinessOwner")
    ),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/MyAccount")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },

  // Customer screens
  {
    exact: true,
    path: "/customer",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CustomerModel/Customer")
    ),
  },
  {
    exact: true,
    path: "/faqs",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/static/Faq")),
  },

  {
    path: "/Subscription",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscription/AllSubscribtions.js")
    ),
  },
  {
    exact: true,
    path: "/create-test",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscription/createTest")
    ),
  },
  {
    exact: true,
    path: "/subscription/create-template",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscription/SubscriptionTemplate")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
