import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
  Popper,
  Fade,
  Paper,
  Divider,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Slide,
  ClickAwayListener,
  FormControlLabel,
  withStyles,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputBase,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { Link, useLocation, useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";
import { BiBell } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { HiOutlineKey } from "react-icons/hi";
import { IoIosHelpCircleOutline, IoMdChatbubbles } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import HelpIcon from "@material-ui/icons/Help";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import Menu from "@material-ui/core/Menu";

import MoreVertIcon from "@material-ui/icons/MoreVert";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: "7px 30px 7px 30px",

    background: theme.palette.background.header,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 0px 20px",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& svg": {
      color: theme.palette.text.primary,
    },
    "& .leftBox": {
      width: "306px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        width: "150px",
        [theme.breakpoints.down("xs")]: {
          width: "104px",
          paddingLeft: "0 !important",
        },
      },
    },
  },
  mainpopperBox: {
    "& .MuiPaper-root": {
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.25) !important",
      borderRadius: "15px",
      marginTop: "70px",
      maxWidth: "300px",
      width: "100%",
    },
    "& h6": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
      letterSpacing: "0em",
      textAlign: "center",
    },
    "& svg": {
      fontSize: "20px",
      marginRight: "10px",
      color: "#9D9D9D",
    },
  },
  mainLeafBox: {
    padding: "15px 14px",
    cursor: "pointer",
    "&:hover": {
      background: "#f5f5f5",
    },
  },
  DevelopersCls: {
    // background: "linear-gradient(262deg, #62D3F0 13.12%, #35A5F5 83.57%)",
    background:
      "var(--Linear, linear-gradient(262deg, #62D3F0 13.12%, #35A5F5 83.57%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  symbols: {
    width: "30px",
    height: "30px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rootPaper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    maxWidth: "463px",
    width: "100%",
    // border:"1px solid red"
    background: "#dddddd",
  },
  serchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "#fff",
  },
  iconButtonSymbols: {
    width: "38px",
    height: "38px",
    flexShrink: "0",
  },
  symbolCls: {
    background: "#fff",
    borderRadius: "5px",
    color: "gray !important",
    padding: "2px",
  },
  addsymbols: {
    background: "#008CF2",
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    color: "#fff !important",
  },
  topBarContain: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
}));

const sidebarData = [
  {
    icon: <FaRegUser />,
    list: "My account",
    route: "/my-account",
  },
  {
    icon: <HiOutlineKey />,
    list: "Password & Security",
    route: "/security",
  },
  {
    icon: <IoShieldCheckmarkOutline />,
    list: "Privacy Policy",
    route: "/privacy",
  },
  {
    icon: <IoIosHelpCircleOutline />,
    list: "Terms & Condition",
    route: "/terms-and-conditions",
  },
  {
    icon: <IoMdChatbubbles />,
    list: "Rules & FAQ’s",
    route: "/faqs",
  },
];

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 46,
    height: 26,
    padding: "2px",
    margin: theme.spacing(1),
    "& .MuiIconButton-root": {
      // backgroundColor: "blue",
      margin: "2px 0px 0px 0px",
    },

    "& .MuiIconButton-root:hover": {
      // backgroundColor: "red",
      margin: "4px 0px 0px 5px",
    },
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translate(21px ,2px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#4ca5f5",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 17,
    height: 17,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

// menus in topbar
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [profileData, setProfileData] = useState({});
  console.log("uygewayffgwyg", profileData.countryCode);

  const [isLogout, setIsLogout] = useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickAway = () => {
    setOpen(false);
  };
  const [placement, setPlacement] = React.useState();
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  useEffect(() => {
    if (auth?.userData) {
      setProfileData({
        profilePic: auth?.userData?.profilePic,
      });
    }
  }, [auth?.userData]);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [newBusiness, setNewBusiness] = useState(1);

  const handleChangeBusiness = (event) => {
    setNewBusiness(event.target.value);
  };

  const NewBusinessDropDown = () => {
    return (
      <Box className="leftBox">
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={newBusiness}
            onChange={handleChangeBusiness}
            startAdornment={
              <IconButton
                className={classes.iconButtonSymbols}
                aria-label="menu"
              >
                <StorefrontTwoToneIcon className={classes.symbolCls} />
              </IconButton>
            }
            style={{ background: "#dddddd" }}
          >
            <MenuItem value={1} disabled>
              New Business
            </MenuItem>
            <MenuItem value={10}>New Business 1</MenuItem>
            <MenuItem value={20}>New Business 2</MenuItem>
            <MenuItem value={30}>New Business 3</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  // menus
  const [anchorElNew, setAnchorElNew] = useState(false);

  const handleClickNew = (event) => {
    setAnchorElNew(event.currentTarget);
  };

  const handleCloseNew = () => {
    setAnchorElNew(!anchorElNew);
  };

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      style={{ boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)" }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            // color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ padding: "0px" }}
          >
            <SvgIcon>
              <MenuIcon style={{ color: "#000", fontSize: "25px" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        &nbsp; &nbsp;
        <Box className={classes.mainheader}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            className={classes.topBarContain}
          >
            <Hidden mdDown>
              <Grid item lg={3} md={3} sm={4} xs={6}>
                <NewBusinessDropDown />
              </Grid>
            </Hidden>

            <Grid lg={4} md={6} sm={6} xs={11}>
              <Paper component="form" className={classes.rootPaper}>
                <IconButton
                  className={classes.iconButtonSymbols}
                  aria-label="menu"
                >
                  <SearchIcon className={classes.symbolCls} />
                </IconButton>
                <InputBase
                  className={classes.serchInput}
                  placeholder="Search anything you want..."
                />
              </Paper>
            </Grid>
            <Hidden mdDown>
              <Grid lg={5} md={3} sm={3} xs={6}>
                <Box
                  className="displayEnd"
                  style={{ gap: "20px", textAlign: "center" }}
                >
                  <Box className="themeButton"></Box>
                  <Box>
                    <Typography
                      variant="h4"
                      className={classes.DevelopersCls}
                      onClick={() => history.push("/developers")}
                    >
                      Developers
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5" className={{}}>
                      Test Mode
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={state.checkedB}
                          onChange={handleChange}
                          name="checkedB"
                        />
                      }
                    />
                  </Box>
                  <HelpIcon className={classes.symbols} />
                  <NotificationsIcon
                    onClick={() => history.push("/notifications")}
                    className={classes.symbols}
                  />
                  <SettingsIcon className={classes.symbols} />
                  <AddIcon className={classes.addsymbols} color="#fff" />

                  <Box className={classes.symbols}></Box>
                  {/* <IconButton
                  onClick={() => history.push("/notification")}
                  style={{
                    background: "rgba(196, 196, 196, 0.4)",
                    padding: "10px",
                  }}
                >
                  <BiBell />
                </IconButton>
                <Box
                  ml={2}
                  onClick={handleClick("left-start")}
                  style={{ cursor: "pointer" }}
                >
                  <Avatar
                    style={{ width: "50px", height: "50px" }}
                    src="images/avatar2.png"
                  />
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleClickAway}
                  >
                    <Popper
                      className={classes.mainpopperBox}
                      open={open}
                      anchorEl={anchorEl}
                      placement={placement}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                background: "#f5f5f5",
                                padding: "10px 14px",
                                borderRadius: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar src="images/topGun.png" />
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: "500",
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Digvijay@
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: "#EC1F24",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                >
                                  View own profile
                                </Typography>
                              </Box>
                            </Box>
                            {sidebarData.map((data) => (
                              <>
                                <Box
                                  className={`${classes.mainLeafBox} displayStart`}
                                  alignItems="flex-start"
                                  onClick={() => history.push(`${data.route}`)}
                                >
                                  <Box>{data.icon}</Box>
                                  <Box>
                                    <Typography variant="h6">
                                      {data.list}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Divider
                                  style={{ background: "rgba(0, 0, 0, 0.2)" }}
                                />
                              </>
                            ))}
                            <Box
                              className="displayStart"
                              alignItems="flex-start"
                              style={{
                                padding: "15px 10px 0",
                                cursor: "pointer",
                              }}
                              onClick={() => setIsLogout(true)}
                            >
                              <Box>
                                <AiOutlineLogout style={{ color: "#EC1F24" }} />
                              </Box>
                              <Box>
                                <Typography
                                  variant="h6"
                                  style={{ color: "#EC1F24" }}
                                >
                                  Logout
                                </Typography>
                              </Box>
                            </Box>
                            {isLogout && (
                              <Dialog
                                maxWidth="xs"
                                fullWidth
                                className={classes.dailogOpen}
                                open={isLogout}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => setIsLogout(false)}
                              >
                                <DialogContent>
                                  <Box
                                    className={classes.dialougTitle}
                                    align="center"
                                  >
                                    <AiOutlineLogout
                                      style={{
                                        marginRight: "16px",
                                        color: "#EC1F24",
                                        fontSize: "65px",
                                      }}
                                    />
                                    <Typography
                                      variant="h5"
                                      style={{
                                        color: "#222222",
                                        fontFamily: "poppins",
                                        fontWeight: "400",
                                        maxWidth: "275px",
                                      }}
                                    >
                                      Are you sure, you want to logout?
                                    </Typography>
                                  </Box>
                                </DialogContent>
                                <DialogActions
                                  style={{
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box mt={2} mb={2}>
                                    <Button
                                      className="modelbtn"
                                      color="secondary"
                                      variant="contained"
                                      style={{ padding: "8px 21px" }}
                                      onClick={() => setIsLogout(false)}
                                    >
                                      No
                                    </Button>
                                    &nbsp; &nbsp;
                                    <Button
                                      className="modelbtnyes"
                                      style={{
                                        padding: "8px 21px",
                                      }}
                                      color="primary"
                                      variant="contained"
                                      onClick={() => {
                                        window.localStorage.removeItem("token");
                                        auth.userLogIn(false, null);
                                        history.push("/");
                                      }}
                                    >
                                      Yes
                                    </Button>
                                  </Box>
                                </DialogActions>
                              </Dialog>
                            )}
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </ClickAwayListener>
                </Box> */}
                </Box>
              </Grid>
            </Hidden>

            <Hidden lgUp>
              <Grid xs={1} className="displayEnd">
                <MoreVertIcon
                  onClick={handleClickNew}
                  onClose={handleCloseNew}
                />
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorElNew}
                  keepMounted
                  open={Boolean(anchorElNew)}
                  onClose={handleCloseNew}
                >
                  <NewBusinessDropDown />
                  <StyledMenuItem>
                    <Typography
                      variant="h4"
                      className={classes.DevelopersCls}
                      onClick={() => history.push("/developers")}
                    >
                      Developers
                    </Typography>
                  </StyledMenuItem>
                  {/* <Typography variant="h4" className={classes.DevelopersCls} onClick={() => history.push("/developers")}>Developers</Typography> */}
                  {/* <Typography variant="h5" className={{}}>Test Mode</Typography> */}
                  <StyledMenuItem
                  // className="flexColumnAlignCenter"
                  >
                    <ListItemIcon>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={state.checkedB}
                            onChange={handleChange}
                            name="checkedB"
                          />
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Test Mode" />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <ListItemIcon>
                      <HelpIcon className={classes.symbols} />
                    </ListItemIcon>
                    <ListItemText primary="help" />
                  </StyledMenuItem>

                  <StyledMenuItem>
                    <ListItemIcon>
                      <NotificationsIcon className={classes.symbols} />
                    </ListItemIcon>
                    <ListItemText primary="Notifications" />
                  </StyledMenuItem>

                  <StyledMenuItem>
                    <ListItemIcon>
                      <SettingsIcon className={classes.symbols} />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </StyledMenuItem>

                  <StyledMenuItem>
                    <ListItemIcon>
                      <AddIcon className={classes.addsymbols} color="#fff" />
                    </ListItemIcon>
                    <ListItemText primary="Additional" />
                  </StyledMenuItem>
                </StyledMenu>
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
          // onClick={() => history.push("/admin-profile")}
        />
      </Box>
    </>
  );
}
