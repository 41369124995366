import React, { useEffect, useState } from "react";
import {
  Grid,
  Link,
  Typography,
  makeStyles,
  Box,
  Container,
  Paper,
  IconButton,
  Hidden,
} from "@material-ui/core";
import Logo from "./../../component/Logo";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  loginLayoutBox: {
    background: "#fff",
    zIndex: "9",
    position: "relative",
    display: "flex",
    justifyContent: "end",
    height: "100vh",
    backgroundImage: "url(images/background.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    "& .backBtn": {
      position: "absolute",
      top: 30,
      left: 20,
      border: "1px solid rgba(0, 0, 0, 0.08)",
      [theme.breakpoints.down("md")]: {
        top: 15,
        left: 15,
      },
    },
    "& .loginLayoutBoxImg": {
      width: "50%",
      zIndex: "1",
      overflow: "hidden",
      position: "fixed",
      backgroundSize: "cover",
      backgroundColor: "#000",
      backgroundImage: "url(images/loginback.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: "100vh",
      backgroundImage: "url(images/loginLayoutimg.png)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      left: 0,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& h1": {
        fontSize: "65px",
      },
    },
    "& .lognLayoutContainer": {
      padding: "15%",
      display: "flex",
      flexDirection: "column",
      gap: "100px",
      "& .loginLAyoutMainBox": {
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        "& .loginLayoutSubBox": {
          display: "flex",
          flexDirection: "column",
          gap: "20px",

          "& .MuiTypography-body2": {
            fontSize: "14px",
            color: "rgba(255, 255, 255, 0.87)",
            textAlign: "center",
            FontFamily: "Sora",
          },
        },
      },
    },
    "& .loginLayoutContentBox": {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      "& .MuiTypography-h3": {
        fontSize: "35px",
        color: "#fff",
        fontWeight: "600",
        textAlign: "center",
        width: "100%",
      },
    },
  },
  loginContentLayoutBox: {
    background: theme.palette.background.white,
    position: "relative",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingLeft: "0px",
    },
  },
  imgBox: {
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
}));
const data = [
  {
    title: "Easy to use Dashboard",
    description:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
  },
];
export default function LoginLayout2({ children }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.loginLayoutBox}>
        <Box className="loginLayoutBoxImg">
          <Box className="lognLayoutContainer">
            <Box className="displayCenter">
              <img src="/images/logo.png" alt="" />
            </Box>
            <Box className="loginLAyoutMainBox">
              <Box
                style={{
                  padding: "20px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderRadius: "25px",
                }}
              >
                <img
                  src="/images/LoginLayout2.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
              {data.map((item, index) => (
                <Box className="loginLayoutSubBox" key={index}>
                  <Box className="loginLayoutContentBox">
                    <Typography variant="h3">{item.title}</Typography>
                  </Box>
                  <Typography variant="body2">{item.description}</Typography>
                </Box>
              ))}
            </Box>
            ;
          </Box>
        </Box>
        <Box className={classes.loginContentLayoutBox}>
          <Box style={{ width: "100%", height: "100%" }}>
            <Box>
              <Box>
                {window.location.pathname === "/login" ? (
                  <></>
                ) : (
                  <>
                    <IconButton
                      variant="contained"
                      className="backBtn"
                      onClick={() => history.goBack()}
                    >
                      <AiOutlineArrowLeft
                        style={{ color: "#000", fontSize: "15px" }}
                      />
                    </IconButton>
                  </>
                )}
              </Box>
              {/* <Hidden lgUp>
                <Box align="center" mt={2}>
                  <img
                    src="images/logo.png"
                    alt="abc"
                    className={classes.imgBox}
                  />
                </Box>
              </Hidden> */}
            </Box>

            <Box
              mt={8}
              style={{ minHeight: "calc(100vh - 65px)" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
LoginLayout2.propTypes = {
  children: PropTypes.node,
};
